div.tutorial-container,
div.tutorial-container-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.tutorial-container {
  background-color: rgba(255, 255, 255, 0.75);
  transition: background-color 0.2s ease;
}

div.tutorial-container.transparent {
  background-color: rgba(255, 255, 255, 0);
}

.tutorial-container-inner {
  flex: 1;
}

div.slide-wrap {
  position: relative;
  height: 307px;
  width: 40%;
  z-index: 2000;
}

div.slide-wrap-small {
  height: 228px;
  width: 30%;
}

div.btns-wrap {
  position: absolute;
  left: -100px;
  top: 0;
  right: -100px;
  display: flex;
  justify-content: space-between;
}

img.action-btn {
  width: 85px;
  height: 85px;
}

button.slide-btn {
  display: inline-flex;
  flex-direction: column;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  background: none;
}
