.login-page-container {
  text-align: center;
  position: relative;
  z-index: 100;
  height: 100%;
}

.login-form-field {
  margin-bottom: 1rem;
}

.login-form-container {
  background-color: #e5e5e5;
  border-radius: 10px;
  width: 50%;
  position: relative;
  margin: 1rem auto;
  padding: 4rem;
}

.login-form-label {
  color: #070707;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.login-form-ip {
  border: 1px solid #707070;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  padding: 0px 10px;
}

.login-error-text {
  font-size: 1rem;
  color: red !important;
  width: 100%;
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
}

.login-back-to-dashboard,
.login-forgot-password {
  cursor: pointer;
  color: #00788a;
}

.login-forgot-password {
  font-size: 1rem;
  margin-top: 0.5rem;
}
