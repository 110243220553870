div.budget-slider-wrap {
  position: relative;
}

div.slider-wrap {
  position: relative;
  width: calc(100% - 75px);
  margin: 3.5rem auto 0 auto;
}

div.savings-indicator-wrap {
  position: absolute;
  top: 0;
  text-align: center;
  transition: opacity 0.3s ease;
}

div.top-indicators-container {
  top: 30%;
  left: 3rem;
  display: flex;
  align-items: baseline;
  text-align: center;
  position: absolute;
}

div.spent-indicator-wrap > p,
div.spending-indicator-wrap > p {
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.spending-indicator-wrap p {
  color: #002f6c;
}

div.spent-indicator-wrap p {
  color: #dc2d2d;
  font-weight: bold;
}

div.budget-slider-stick-wrap {
  text-align: center;
  margin: 0 auto;
}

div.slider-input-wrap {
  position: relative;
  height: 75px;
  margin-left: auto;
}

div.slider-input-wrap-inner {
  width: calc(100% + 75px);
  transform: translateX(-37.5px);
  height: 100%;
  position: relative;
}

.slider-wrap input:focus {
  background: none;
  outline: none;
}

.slider-wrap input {
  width: 100%;
  -webkit-appearance: none;
  z-index: 2;
  opacity: 0;
  direction: rtl;
  height: 100%;
  background: none;
}

.slider-wrap input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: #37adbf;
  cursor: pointer;
  border: 0 !important;
  transform: translateY(calc(-50% + 4px));
}

.slider-wrap input::-moz-budget-slider-wrap-thumb {
  -webkit-appearance: none;
  width: 75px;
  height: 75px;
  margin: -3px 0 0;
  border-radius: 50%;
  background: #37adbf;
  cursor: pointer;
  border: 0 !important;
}

.slider-wrap input::-ms-thumb {
  width: 75px;
  height: 75px;
  margin: -3px 0 0;
  border-radius: 50%;
  background: #37adbf;
  cursor: pointer;
  border: 0 !important;
}

.slider-wrap input::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #b2b2b2;
  border-radius: 3px;
}

.slider-wrap input::-moz-budget-slider-wrap-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #b2b2b2;
  border-radius: 3px;
}

.slider-wrap input::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #b2b2b2;
  border-radius: 3px;
}

div.slider-scale-wrap {
  height: 40px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  transform: translateY(calc(-100% - 15px));
}

div.ticks-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

span.tick {
  position: relative;
}
span.tick:after {
  content: '';
  position: absolute;
  background-color: #000;
  width: 2px;
  height: 15px;
  top: 0;
  left: 0px;
  transform: translateX(-50%);
}

div.tick-labels-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

span.tick-label {
  display: inline-block;
  position: relative;
}

span.tick-label-inner {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-50%);
}
