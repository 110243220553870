div.app-container {
  width: 1024px;
  height: 768px;
  margin: auto;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

div.cookie-snackbar {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  height: 300px;
  width: 400px;
  background-color: #fff;
  z-index: 1000;
  animation: fadeIn 1s forwards ease;
}

div.cookie-snackbar-inner {
  height: 100%;
  text-align: center;
  color: #00788a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

div.cookie-snackbar-inner > h3 {
  margin: 1rem 0;
}

div.cookie-snackbar-inner > p {
  font-size: 1rem;
  color: #000;
  margin-bottom: 1rem;
}

div.cookie-snackbar-inner > span {
  display: inline-block;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
