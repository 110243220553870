div.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.close-btn {
  position: absolute;
  top: 3.5rem;
  right: 4.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: inline-block;
}
