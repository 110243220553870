div.scout-page-board-inner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
}

div.scout-page-board-inner > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.5rem;
}

div.scout-page-board-header {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.scout-page-board-header-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
}

p.scout-page-helper-text {
  font-size: 1.1rem;
  margin: 0.25rem auto;
  text-align: center;
  width: 35%;
}

p.money-level-text {
  font-size: 0.8rem;
  text-align: center;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

div.scout-board {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 5px solid #4b4b4b;
  border-radius: 10px;
  background: #fff;
  width: 406px;
  height: 487px;
  position: relative;
}

div.scout-board-disabled {
  pointer-events: none;
}

div.scout-page-board-left > p {
  left: 0;
  text-align: center;
  margin-top: -15px;
  font-size: 0.8rem;
}

div.scout-page-board-right {
  min-width: 407.97px;
  min-height: 512.93px;
}

div.available-player-row,
div.offered-player-row-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.available-player-row {
  justify-content: space-around;
}

div.player-card-drop {
  min-width: calc(90px + 1rem);
  min-height: calc(123px + 1rem);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.player-card-drop-small {
  min-width: calc(72px + 0.5rem);
  min-height: calc(95.76px + 0.5rem);
}

div.offered-player-row {
  width: 392px;
  height: 144px;
  border: 5px solid #4b4b4b;
  border-radius: 10px;
  background: #fff;
}

div.offered-player-row-wrap {
  position: relative;
}

span.money-level-short {
  font-size: 1.1rem;
  position: absolute;
  top: calc(0.5rem + 17px);
  left: 0.5rem;
  font-weight: bold;
}

div.offered-player-row-inner {
  height: 100%;
}

div.offered-player-row-inner.level-3 {
  width: 88%;
  margin: 0 auto;
}

div.offered-player-row div.player-card-drop {
  padding: 0.5rem 0.5rem;
}

div.offered-player-row div.player-card-drop-small {
  padding: 0.5rem 0.25rem;
}

.check-btn-small {
  margin-left: 0.5rem;
}

div.empty-player-slot {
  width: 90px;
  height: 106.4px;
  border-radius: 15px;
  border: 3px solid #707070;
  background-color: #e5e5e5;
  animation: fadeIn 0.2s forwards ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
