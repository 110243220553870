.confirm-message {
  text-align: center;
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  color: #006d75;
  position: relative;
  transform: translateY(50%);
}

.confirm-options {
  transform: scale(0.75);
}

.confirm-overlay-top {
  flex: 1;
  width: 100%;
}
