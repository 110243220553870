div.stick-btn-wrap {
  position: relative;
  width: 415px;
  height: 110px;
}

span.stick-btn-link {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

span.stick-btn-link-disabled {
  pointer-events: none;
}

.stick-btn-img {
  position: absolute;
  bottom: -29%;
  left: 0;
}

.stick-btn-img-inverse {
  bottom: -40%;
}

div.stick-btn-small {
  transform: scale(0.8);
}

p.stick-btn-text-right {
  margin-top: 0.15rem;
}
