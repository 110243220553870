.player-rank-pie * {
  box-sizing: border-box;
}

.player-rank-pie {
  position: relative;
  z-index: 0;
}
.player-rank-slice {
  clip-path: polygon(50% 0%, 50% 100%, 100% 100%, 100% 0%);
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.player-rank-slice-fill {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip-path: polygon(50% 0%, 50% 100%, 0% 100%, 0% 0%);
  position: absolute;
  z-index: 2;
}

.player-rank-bg-fill {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  display: none;
  z-index: 1;
  clip-path: polygon(50% 0%, 50% 100%, 0% 100%, 0% 0%);
  transform: rotate(180deg);
}
