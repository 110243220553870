div.snackbar-wrap {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  background-color: #f1f1f1;
  border-radius: 3px;
  width: 400px;
  max-width: 90%;
  padding: 1rem;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.snackbar-wrap > span {
  display: inline-block;
  margin-left: 0.5rem;
}
