div.teacher-dashboard-header {
  min-height: 125px;
  max-height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

div.teacher-dashboard-header > h1 {
  font-size: 1.75rem;
  color: #002f6c;
  font-weight: bold;
}

div.teacher-dashboard-body {
  padding: 3rem;
  background-color: rgba(238, 238, 238, 0.5);
  flex: 1;
  min-height: calc(100% - 125px);
  overflow-y: auto;
}

div.class-stats-wrap {
  padding: 1rem;
  margin: 1rem 0;
}

div.class-stats-wrap > h4 {
  margin-bottom: 1rem;
}

div.class-stat {
  display: flex;
  margin: 0.5rem 0;
}

div.class-stat > p {
  color: #002f6c;
  font-size: 1.1rem;
}

p.class-stat-value {
  margin-left: 1rem;
  font-weight: bold;
}

div.class-stats-wrap,
div.student-table {
  background-color: #fff;
  border-radius: 3px;
}

div.student-table-row {
  display: flex;
  padding: 1rem;
}

div.student-table-row:not(:first-child):not(:last-child) {
  border-bottom: 1px solid #00788a;
}

div.student-table-header-row {
  border-bottom: 2px solid #00788a;
}

div.student-table-row > div {
  font-size: 1rem;
  flex: 1;
  align-items: center;
  display: flex;
  padding: 0 0.25rem;
}

div.student-table-header-row > div {
  font-size: 0.9rem;
  font-weight: 700;
  color: #00788a;
}

div.teacher-dashboard-btns-wrap > button:first-child {
  margin-right: 0.5rem;
}

span.action-btn {
  cursor: pointer;
}

span.action-btn:first-child {
  margin-right: 0.5rem;
}

div.teacher-dashboard-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 1024px;
  margin: 0 auto;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.modal-bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

div.teacher-dashboard-modal-inner {
  background-color: #fff;
  border-radius: 3px;
  width: 85%;
  margin: 0 auto;
  padding: 3rem;
  position: relative;
  z-index: 2;
  max-height: 85vh;
  overflow: auto;
}

.close-btn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: inline-block;
}

div.teacher-dashboard-modal-inner > div.modal-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

div.modal-template label {
  margin-top: 1rem;
  display: block;
}

div.modal-template input:not([type='file']) {
  border: 1px solid #707070;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  padding: 0px 10px;
}

div.modal-template input[type='file'] {
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 1rem;
  margin: 1rem 0;
}

div.modal-template button {
  margin-top: 1rem;
}

div.confirm-text {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

div.empty-table {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
