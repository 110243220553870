div.market-players-board-inner {
  border: 5px solid #4b4b4b;
  border-radius: 5px;
  padding: 0 1rem 1rem 1rem;
  min-height: 206.86px;
}

h3.market-players-board-title {
  font-size: 1.5rem;
  text-align: center;
  padding: 0.5rem 0;
}

div.market-players-board-btns-container {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

div.market-players-board-tab-btn.disabled {
  background-color: #707070;
  cursor: initial;
}

div.market-players-board-tab-btn {
  cursor: pointer;
  display: inline-flex;
  position: relative;
  font-size: 1.65rem;
  color: #fff;
  align-items: center;
  justify-content: center;
  background-color: #ea7200;
  height: 50px;
  width: 175px;
  border-radius: 5px;
  z-index: 1;
}

div.market-players-board-tab-btn-inner {
  height: 22px;
  display: flex;
  align-items: center;
  color: #fff;
}

div.market-players-board-tab-btn:before,
div.market-players-board-tab-btn:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  bottom: 0;
  border-radius: 5px;
}

div.market-players-board-tab-btn:after {
  transform-origin: left top;
  transform: skewX(-10deg);
}

div.market-players-board-tab-btn:before {
  transform-origin: right top;
  transform: skewX(10deg);
}

div.market-players-board-inner {
  flex: 1;
}

div.market-players-board-players-wrap {
  display: flex;
  align-items: center;
}
