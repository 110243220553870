div.sharkie-wrap {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

img.bubble-img,
img.sharkie-img {
  display: inline-block;
  width: 100%;
}

div.bubble-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.bubble-img {
  width: 110%;
  position: absolute;
  top: 0;
}

div.bubble-wrap:not(.bubble-inverse) img {
  left: 0;
}

div.bubble-inverse img {
  right: 0;
}

p.slide-msg {
  position: relative;
  padding: 1rem 1.5rem;
  text-align: center;
}

div.bubble-inverse img,
div.sharkie-inverse img {
  transform: scaleX(-1);
}

div.sharkie-img-wrap {
  width: 85%;
  position: absolute;
  top: 0;
  left: 0;
}

div.sharkie-present-wrap > div.sharkie-img-wrap,
div.sharkie-presentInverse-wrap > div.sharkie-img-wrap {
  width: 110%;
}
