div.team-page-board-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

div.team-page-board-header > h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

div.team-page-board-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 2rem;
}

div.team-page-board-inner {
  display: flex;
  height: calc(100% - 80px);
}

div.team-page-board-left {
  padding: 0 1rem;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

div.team-page-board-right {
  flex: 1;
  padding-right: 1rem;
}

div.team-players-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  position: relative;
  flex: 1;
  padding-top: 0.25rem;
}

div.team-players-row-2 {
  flex: 1.5;
  align-items: flex-start;
  padding-top: 1.25rem;
}

div.team-players-card {
  height: 335px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

div.team-players-card {
  color: #006b77;
  border: 5px solid #4b4b4b;
  border-radius: 5px;
  background: white;
}
