.loading-spinner {
  display: inline-block;
  width: 70px;
  height: 70px;
  border: 4px solid rgba(0, 120, 138, 0.3);
  border-radius: 50%;
  border-top-color: #00788a;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

.loading-spinner-small {
  width: 40px;
  height: 40px;
  border-width: 3px;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
