div.budget-equation-wrap {
  border: 10px solid #00788a;
  border-radius: 15px;
  background: #4b4b4b;
  color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 0.25rem 0;
  width: 500px;
  margin: 0 auto;
}

h4.budget-equation-title {
  text-align: center;
  font-size: 1.75rem;
  font-weight: bold;
  white-space: nowrap;
}

div.equation-wrap {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0 0.25rem 0;
}

div.equation-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.equation-inner.savings {
  margin: 0 1.5rem 0 1rem;
}

span.equation-title {
  font-size: 1.15rem;
  font-weight: bold;
}

span.equation-amount {
  font-size: 2.5rem;
  margin-top: 0.5rem;
}

div.equation-inner.savings > span.equation-amount {
  position: relative;
}

span.equation-operator {
  font-size: 3rem;
  align-self: flex-end;
}
