div.admin-page-header {
  min-height: 125px;
  max-height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

div.admin-page-header > a {
  font-size: 1.75rem;
  color: #002f6c;
  font-weight: bold;
}

div.admin-page-body-container {
  padding: 3rem;
  background-color: rgba(238, 238, 238, 0.5);
  flex: 1;
  min-height: calc(100% - 125px);
  overflow-y: auto;
}

div.admin-total-wrap {
  font-size: 2rem;
  background-color: #fff;
  color: #002f6c;
  border-radius: 3px;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.admin-total-wrap {
  margin-bottom: 2rem;
}

div.admin-total-left {
  display: flex;
  align-items: center;
}

div.admin-total-left > div.loading-spinner,
div.admin-total-left > span {
  margin-right: 0.65rem;
}

div.admin-total-left > span {
  font-size: 2.5rem;
  color: #00788a;
}

div.admin-loading-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.teacher-browser-header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.teacher-browser-header-wrap > h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #002f6c;
}

div.admin-teacher-detail-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1rem;
}

div.get-time-spent-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

div.get-time-spent-wrap > span {
  color: #002f6c;
}

div.admin-teacher-details-inner {
  display: flex;
  padding: 1rem 3rem;
}

div.admin-teacher-details-left,
div.admin-teacher-details-right {
  flex: 1;
}

div.admin-teacher-details-right {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

div.admin-teacher-detail-action {
  position: absolute;
  bottom: 32px;
  right: 32px;
  display: flex;
  flex-direction: column;
}
div.admin-teacher-detail-action-item {
  margin: 12px;
}

span.admin-teacher-detail-label {
  display: inline-block;
  flex: 1;
  font-weight: bold;
  color: #00788a;
}

span.admin-teacher-detail {
  display: inline-block;
  flex: 1;
}

div.teacher-browser-search-wrap > p {
  margin-bottom: 1rem;
  font-size: 1rem;
}

div.teacher-search-input-wrap {
  display: flex;
  margin-bottom: 1.5rem;
}

div.teacher-search-input-wrap > span {
  flex: 0.2;
}

div.teacher-search-input-wrap > input {
  display: block;
  flex: 1;
  background: none;
  border: none;
  border-bottom: 1px solid #00788a;
  transition: all 0.2s ease;
  padding: 0 0.25rem;
}

div.teacher-search-input-wrap > input:focus {
  border-color: #f3901d;
}

div.time-spent-container {
  color: #002f6c;
  padding: 1rem;
}

div.time-spent-wrap {
  margin-top: 2rem;
}

div.time-spent-wrap > div {
  font-size: 1.5rem;
  color: #00788a;
  margin: 0.5rem 0;
}

div.teacher-browser-modal-inner {
  background-color: #fff;
  border-radius: 3px;
  width: 85%;
  margin: 0 auto;
  padding: 3rem;
  position: relative;
  z-index: 2;
  max-height: 85vh;
  overflow: auto;
}

div.teacher-browser-modal-inner > div.modal-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

div.teacher-browser-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 1024px;
  margin: 0 auto;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.modal-bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.close-btn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: inline-block;
}