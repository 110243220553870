.objectives-board-wrap {
  border: 10px solid #00788a;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  background: #4b4b4b;
  color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  min-width: 350px;
  transition: all 0.3s ease;
}

.objectives-board-wrap.is-urgent {
  border-color: #ffd782;
}

.objective-level-box {
  display: flex;
  justify-content: space-between;
  font-size: 1.75rem;
  font-weight: bold;
}

.ordered-list {
  list-style-type: decimal;
  transition: all 0.3s ease;
  list-style-position: inside;
}

.ordered-list > li {
  margin: 0.5rem 0;
}

.ordered-list > li.objective-complete {
  text-decoration: line-through;
  opacity: 0.8;
}

.ordered-list > li.is-urgent {
  color: #ffd782;
}

.objective-text-small {
  font-size: 1.05rem;
}
