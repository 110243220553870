div.jumbotron-wrap {
  display: flex;
  height: 300.19px;
  position: relative;
  width: 464px;
  z-index: 0;
}

div.jumbotron-border {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #ababab;
}

div.jumbotron-border-left {
  transform: skewX(10deg);
  left: -35px;
}

div.jumbotron-border-right {
  transform: skewX(-10deg);
  right: -35px;
}

div.jumbotron-main-section {
  background: #707070;
  text-align: center;
  height: 100%;
  flex: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

div.game-on-top {
  display: flex;
  position: relative;
  flex: 1;
}

div.game-on-top-left,
div.game-on-top-right {
  flex: 1;
  padding: 1rem 1rem 0.5rem 1rem;
}

div.game-on-bottom {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.25rem;
}

h2.jumbotron-message {
  width: 550px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #00788a;
  color: #fff;
  border: 5px solid rgba(0, 0, 0, 0);
}

h2.jumbotron-message.first-period {
  background-color: #e5e5e5;
  color: #00788a;
}

h2.jumbotron-message.second-period {
  color: #ffd782;
}

h2.jumbotron-message.third-period {
  background-color: #e5e5e5;
  color: #dc2d2d;
}

div.jumbotron-score-container {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

div.jumbotron-score-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1.75rem;
  color: #fff;
}

div.jumbotron-score-wrap {
  width: 125px;
  height: 92px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 3rem;
  color: #121210;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.transition-view-container {
  display: flex;
  flex: 1;
}

div.transition-view-left,
div.transition-view-right {
  max-width: 50%;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

div.transition-view-left {
  padding: 1rem;
}

div.transition-view-right {
  padding: 0.5rem 1rem 0.5rem 0;
}

div.jumbotron-stats-container {
  color: #fff;
  width: calc(100% + 0.5rem);
  border: 5px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  transform-origin: center 80%;
  margin-top: 1rem;
  padding: 0.25rem;
}

div.jumbotron-stats-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.jumbotron-stat-wrap {
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.jumbotron-stat-inner {
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 2.5rem;
  color: #121210;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

div.jumbotron-coming-up-container {
  flex: 1;
}

div.jumbotron-coming-up-container,
div.jumbotron-next-opponent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

div.jumbotron-next-opponent-container > h3 {
  color: #fff;
  font-size: 1.15rem;
}

div.jumbotron-upcoming-games-container {
  flex: 1;
  margin-top: 0.25rem;
  width: 100%;
}

div.jumbotron-upcoming-games-container > h4 {
  color: #fff;
  font-size: 1.05rem;
}

div.jumbotron-next-opponent-card {
  padding: 0.25rem;
  height: 93px;
}

div.jumbotron-next-opponent-card,
div.jumbotron-upcoming-games-card {
  flex: 1;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform-origin: bottom;
  border: 5px solid rgba(0, 0, 0, 0);
}

div.coming-up-opponent-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
}

span.coming-up-opponent-name {
  font-size: 1rem;
  border: 2px solid #707070;
  text-align: center;
  width: 100px;
  height: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
  border-radius: 10px;
}

div.jumbotron-upcoming-games-card {
  height: 140px;
  margin-top: 0.15rem;
  text-align: center;
}

div.upcoming-games-row > div {
  transform: scale(0.8);
}

div.upcoming-games-row:first-child > div:first-child {
  transform-origin: right 90%;
}

div.upcoming-games-row:first-child > div:last-child {
  transform-origin: left 90%;
}

div.upcoming-games-row:last-child > div:first-child {
  transform-origin: right 10%;
}

div.upcoming-games-row:last-child > div:last-child {
  transform-origin: left 10%;
}

video.jumbotron-video {
  object-fit: fill;
  height: 100%;
}
