.forgot-password-page-container {
  text-align: center;
  position: relative;
  z-index: 100;
  height: 100%;
}

.forgot-password-form-wrap {
  background-color: #e5e5e5;
  width: 50%;
  position: relative;
  margin: 1rem auto;
  border-radius: 10px;
  padding: 2rem;
}
.forgot-password-form-wrap > p.form-title {
  padding-top: 1rem;
}

.forgot-password-form-inner {
  padding: 2rem;
}

.form-field {
  margin-bottom: 1rem;
}

.form-label {
  color: #070707;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.form-ip {
  border: 1px solid #707070;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  padding: 0px 10px;
}

.error-text {
  font-size: 1rem;
  color: red !important;
  width: 100%;
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
}

div.try-again-wrap > span,
.back-to-dashboard {
  cursor: pointer;
  color: #00788a;
}

div.try-again-wrap {
  margin-top: 1rem;
  font-size: 1rem;
}
