div.team-slots-board {
  width: 100%;
  border: 5px solid #4b4b4b;
  border-radius: 5px;
  padding: 1rem;
}

div.team-slots-board-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.team-slots-board-row:not(:last-child) {
  margin-bottom: 0.5rem;
}

div.team-slots-board-row > span:first-child {
  font-size: 1.75rem;
  font-weight: bold;
}

div.team-slots-board-row > span:last-child {
  font-size: 2rem;
}

div.market-players-board-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sign-player-question {
  text-align: center;
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #006d75;
  position: relative;
  transform: translateY(50%);
  -webkit-text-stroke: 0.05px black;
}

.sign-player-options {
  transform: scale(0.75);
}
