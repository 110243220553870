@font-face {
  font-family: 'interstate';
  src: url('../fonts/interstate-regular-comp-58b641cc0aca1.otf');
}

@font-face {
  font-family: 'made-tommy';
  src: url('../fonts/MADE_TOMMY/MADE\ TOMMY\ Regular.otf');
}

@font-face {
  font-family: 'made-tommy-outline';
  src: url('../fonts/MADE_TOMMY_OUTLINE/MADE\ TOMMY\ Regular\ Outline.otf');
}

@font-face {
  font-family: 'made-tommy-bold';
  src: url('../fonts/MADE_TOMMY/MADE\ TOMMY\ Bold.otf');
}

html {
  background-color: #eee;
}

* {
  box-sizing: border-box;
}

#root {
  position: relative;
  min-height: 100vh;
}

text {
  font-family: made-tommy-bold;
  color: white;
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

body,
button {
  font-family: made-tommy, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.25rem;
}

button {
  background: none;
  outline: none;
  border: none;
}

a.btn-primary,
input[type='submit'],
button.btn-primary {
  background-color: #00788a;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem 1.5rem;
  border-radius: 3px;
  cursor: pointer;
}

a.btn-accent,
div.btn-accent,
input[type='submit'],
button.btn-accent {
  background-color: #f3901d;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem 1.5rem;
  border-radius: 3px;
  cursor: pointer;
}

a.btn-danger,
div.btn-danger,
button.btn-danger {
  background-color: #dc3545;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem 1.5rem;
  border-radius: 3px;
  cursor: pointer;
}

a.btn-small,
button.btn-small {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
}

a.btn-full-width,
div.btn-full-width,
button.btn-full-width {
  width: 100%;
}

.color-primary {
  color: #00788a;
}

.color-accent {
  color: #f3901d;
}

.color-danger {
  color: #dc3545;
}

.color-dark {
  color: #002f6c;
}

.font-xl {
  font-size: 2.95rem;
}

*.transparent {
  position: relative;
  opacity: 0;
}

h1.page-title {
  color: #00788a;
  text-shadow: 0 0 5px #f3901d, 0 0 5px #f3901d, 0 0 5px #f3901d,
    0 0 5px #f3901d;
  font-size: 5.25rem;
}

h2 {
  font-size: 3.25rem;
}

h4 {
  font-size: 1.5rem;
}

p {
  color: #00788a;
}

.card {
  color: #006b77;
  border: 5px solid #4b4b4b;
  border-radius: 10px;
  background: white;
  position: relative;
  width: 210px;
  height: 270px;
}

.auto-card {
  width: 100%;
  height: auto;
}

.font-bold {
  font-weight: bold;
}

.outline-black {
  text-shadow: 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000;
}

.outline-accent {
  text-shadow: 0 0 5px #f3901d, 0 0 5px #f3901d, 0 0 5px #f3901d,
    0 0 5px #f3901d;
}

.border-primary {
  border: 3px solid #00788a;
}

.border-accent {
  border: 3px solid #f3901d;
}

.border-highlight {
  border-color: #ffd782;
  border-width: 5px;
}

.box-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.text-link {
  color: inherit;
  text-decoration: none;
}

.check-btn {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  box-sizing: border-box;
  border: 8.5px solid #4b4b4b;
  border-radius: 50%;
  background-color: #00788a;
  display: inline-block;
  position: relative;
}

.check-btn:after {
  opacity: 1;
  height: 24px;
  width: 10.3px;
  transform-origin: left top;
  border-right: 8.5px solid #f3901d;
  border-bottom: 8.5px solid #f3901d;
  content: '';
  left: 25.7px;
  top: 0px;
  position: absolute;
  transform: rotate(45deg);
}

.check-btn-small {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  box-sizing: border-box;
  border: 5px solid #4b4b4b;
  border-radius: 50%;
  background-color: #00788a;
  display: inline-block;
  position: relative;
}

.check-btn-small:after {
  opacity: 1;
  height: 14px;
  width: 6px;
  transform-origin: left top;
  border-right: 5px solid #f3901d;
  border-bottom: 5px solid #f3901d;
  content: '';
  left: 15px;
  top: 0px;
  position: absolute;
  transform: rotate(45deg);
}

div.page-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}
