div.budget-page-board-inner {
  flex-direction: column;
  padding: 1rem;
  position: relative;
}

p.helper-text {
  width: 40%;
  margin: 1rem auto;
  text-align: center;
}

div.budget-slider-container {
  margin-top: -6.5rem;
}

div.rollover-budget-wrap {
  position: absolute;
  left: 1rem;
  top: 1rem;
  text-align: center;
  width: 150px;
}

div.rollover-budget-wrap > p {
  font-size: 1rem;
  margin-top: 0.5rem;
}

span.rollover-budget-indicator-wrap {
  display: inline-block;
  cursor: pointer;
}

div.rollover-budget-overlay-header-wrap > p {
  text-align: center;
  width: 500px;
  margin: 0 auto;
}

div.rollover-budget-slider-wrap {
  margin-top: -1rem;
  margin-bottom: 3rem;
}
