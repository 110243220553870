/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type='search'] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

@font-face {
  font-family: 'interstate';
  src: url(/static/media/interstate-regular-comp-58b641cc0aca1.deae83d1.otf);
}

@font-face {
  font-family: 'made-tommy';
  src: url("/static/media/MADE TOMMY Regular.f170ba4f.otf");
}

@font-face {
  font-family: 'made-tommy-outline';
  src: url("/static/media/MADE TOMMY Regular Outline.3aa55d70.otf");
}

@font-face {
  font-family: 'made-tommy-bold';
  src: url("/static/media/MADE TOMMY Bold.4eabcbca.otf");
}

html {
  background-color: #eee;
}

* {
  box-sizing: border-box;
}

#root {
  position: relative;
  min-height: 100vh;
}

text {
  font-family: made-tommy-bold;
  color: white;
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

body,
button {
  font-family: made-tommy, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.25rem;
}

button {
  background: none;
  outline: none;
  border: none;
}

a.btn-primary,
input[type='submit'],
button.btn-primary {
  background-color: #00788a;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem 1.5rem;
  border-radius: 3px;
  cursor: pointer;
}

a.btn-accent,
div.btn-accent,
input[type='submit'],
button.btn-accent {
  background-color: #f3901d;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem 1.5rem;
  border-radius: 3px;
  cursor: pointer;
}

a.btn-danger,
div.btn-danger,
button.btn-danger {
  background-color: #dc3545;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem 1.5rem;
  border-radius: 3px;
  cursor: pointer;
}

a.btn-small,
button.btn-small {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
}

a.btn-full-width,
div.btn-full-width,
button.btn-full-width {
  width: 100%;
}

.color-primary {
  color: #00788a;
}

.color-accent {
  color: #f3901d;
}

.color-danger {
  color: #dc3545;
}

.color-dark {
  color: #002f6c;
}

.font-xl {
  font-size: 2.95rem;
}

*.transparent {
  position: relative;
  opacity: 0;
}

h1.page-title {
  color: #00788a;
  text-shadow: 0 0 5px #f3901d, 0 0 5px #f3901d, 0 0 5px #f3901d,
    0 0 5px #f3901d;
  font-size: 5.25rem;
}

h2 {
  font-size: 3.25rem;
}

h4 {
  font-size: 1.5rem;
}

p {
  color: #00788a;
}

.card {
  color: #006b77;
  border: 5px solid #4b4b4b;
  border-radius: 10px;
  background: white;
  position: relative;
  width: 210px;
  height: 270px;
}

.auto-card {
  width: 100%;
  height: auto;
}

.font-bold {
  font-weight: bold;
}

.outline-black {
  text-shadow: 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000;
}

.outline-accent {
  text-shadow: 0 0 5px #f3901d, 0 0 5px #f3901d, 0 0 5px #f3901d,
    0 0 5px #f3901d;
}

.border-primary {
  border: 3px solid #00788a;
}

.border-accent {
  border: 3px solid #f3901d;
}

.border-highlight {
  border-color: #ffd782;
  border-width: 5px;
}

.box-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.text-link {
  color: inherit;
  text-decoration: none;
}

.check-btn {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  box-sizing: border-box;
  border: 8.5px solid #4b4b4b;
  border-radius: 50%;
  background-color: #00788a;
  display: inline-block;
  position: relative;
}

.check-btn:after {
  opacity: 1;
  height: 24px;
  width: 10.3px;
  transform-origin: left top;
  border-right: 8.5px solid #f3901d;
  border-bottom: 8.5px solid #f3901d;
  content: '';
  left: 25.7px;
  top: 0px;
  position: absolute;
  transform: rotate(45deg);
}

.check-btn-small {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  box-sizing: border-box;
  border: 5px solid #4b4b4b;
  border-radius: 50%;
  background-color: #00788a;
  display: inline-block;
  position: relative;
}

.check-btn-small:after {
  opacity: 1;
  height: 14px;
  width: 6px;
  transform-origin: left top;
  border-right: 5px solid #f3901d;
  border-bottom: 5px solid #f3901d;
  content: '';
  left: 15px;
  top: 0px;
  position: absolute;
  transform: rotate(45deg);
}

div.page-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

div.app-container {
  width: 1024px;
  height: 768px;
  margin: auto;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

div.cookie-snackbar {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  height: 300px;
  width: 400px;
  background-color: #fff;
  z-index: 1000;
  animation: fadeIn 1s forwards ease;
}

div.cookie-snackbar-inner {
  height: 100%;
  text-align: center;
  color: #00788a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

div.cookie-snackbar-inner > h3 {
  margin: 1rem 0;
}

div.cookie-snackbar-inner > p {
  font-size: 1rem;
  color: #000;
  margin-bottom: 1rem;
}

div.cookie-snackbar-inner > span {
  display: inline-block;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

div.team-slots-board {
  width: 100%;
  border: 5px solid #4b4b4b;
  border-radius: 5px;
  padding: 1rem;
}

div.team-slots-board-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.team-slots-board-row:not(:last-child) {
  margin-bottom: 0.5rem;
}

div.team-slots-board-row > span:first-child {
  font-size: 1.75rem;
  font-weight: bold;
}

div.team-slots-board-row > span:last-child {
  font-size: 2rem;
}

div.market-players-board-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.sign-player-question {
  text-align: center;
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #006d75;
  position: relative;
  transform: translateY(50%);
  -webkit-text-stroke: 0.05px black;
}

.sign-player-options {
  transform: scale(0.75);
}

.login-page-container {
  text-align: center;
  position: relative;
  z-index: 100;
  height: 100%;
}

.login-form-field {
  margin-bottom: 1rem;
}

.login-form-container {
  background-color: #e5e5e5;
  border-radius: 10px;
  width: 50%;
  position: relative;
  margin: 1rem auto;
  padding: 4rem;
}

.login-form-label {
  color: #070707;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.login-form-ip {
  border: 1px solid #707070;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  padding: 0px 10px;
}

.login-error-text {
  font-size: 1rem;
  color: red !important;
  width: 100%;
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
}

.login-back-to-dashboard,
.login-forgot-password {
  cursor: pointer;
  color: #00788a;
}

.login-forgot-password {
  font-size: 1rem;
  margin-top: 0.5rem;
}

div.btn {
  cursor: pointer;
  display: inline-flex;
  position: relative;
  font-size: 2rem;
  color: #fff;
  align-items: center;
  justify-content: center;
  height: 78px;
  width: 274px;
  border-radius: 15px;
  z-index: 1;
}

div.btn.btn-small {
  height: 64px;
  width: 225px;
}

div.btn.disabled {
  opacity: 0.5;
}

div.btn-inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

div.btn:before,
div.btn:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  bottom: 0;
  border-radius: 15px;
}

div.btn:after {
  transform-origin: left top;
  transform: skewX(-10deg);
}

div.btn:before {
  transform-origin: right top;
  transform: skewX(10deg);
}

div.buttons-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

img.btn-img {
  max-width: 50%;
  max-height: 90%;
  margin-right: 1rem;
  display: inline-block;
}

div.jumbotron-wrap {
  display: flex;
  height: 300.19px;
  position: relative;
  width: 464px;
  z-index: 0;
}

div.jumbotron-border {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #ababab;
}

div.jumbotron-border-left {
  transform: skewX(10deg);
  left: -35px;
}

div.jumbotron-border-right {
  transform: skewX(-10deg);
  right: -35px;
}

div.jumbotron-main-section {
  background: #707070;
  text-align: center;
  height: 100%;
  flex: 1 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

div.game-on-top {
  display: flex;
  position: relative;
  flex: 1 1;
}

div.game-on-top-left,
div.game-on-top-right {
  flex: 1 1;
  padding: 1rem 1rem 0.5rem 1rem;
}

div.game-on-bottom {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.25rem;
}

h2.jumbotron-message {
  width: 550px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #00788a;
  color: #fff;
  border: 5px solid rgba(0, 0, 0, 0);
}

h2.jumbotron-message.first-period {
  background-color: #e5e5e5;
  color: #00788a;
}

h2.jumbotron-message.second-period {
  color: #ffd782;
}

h2.jumbotron-message.third-period {
  background-color: #e5e5e5;
  color: #dc2d2d;
}

div.jumbotron-score-container {
  flex: 1 1;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

div.jumbotron-score-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1.75rem;
  color: #fff;
}

div.jumbotron-score-wrap {
  width: 125px;
  height: 92px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 3rem;
  color: #121210;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.transition-view-container {
  display: flex;
  flex: 1 1;
}

div.transition-view-left,
div.transition-view-right {
  max-width: 50%;
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

div.transition-view-left {
  padding: 1rem;
}

div.transition-view-right {
  padding: 0.5rem 1rem 0.5rem 0;
}

div.jumbotron-stats-container {
  color: #fff;
  width: calc(100% + 0.5rem);
  border: 5px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  transform-origin: center 80%;
  margin-top: 1rem;
  padding: 0.25rem;
}

div.jumbotron-stats-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.jumbotron-stat-wrap {
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.jumbotron-stat-inner {
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 2.5rem;
  color: #121210;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

div.jumbotron-coming-up-container {
  flex: 1 1;
}

div.jumbotron-coming-up-container,
div.jumbotron-next-opponent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

div.jumbotron-next-opponent-container > h3 {
  color: #fff;
  font-size: 1.15rem;
}

div.jumbotron-upcoming-games-container {
  flex: 1 1;
  margin-top: 0.25rem;
  width: 100%;
}

div.jumbotron-upcoming-games-container > h4 {
  color: #fff;
  font-size: 1.05rem;
}

div.jumbotron-next-opponent-card {
  padding: 0.25rem;
  height: 93px;
}

div.jumbotron-next-opponent-card,
div.jumbotron-upcoming-games-card {
  flex: 1 1;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform-origin: bottom;
  border: 5px solid rgba(0, 0, 0, 0);
}

div.coming-up-opponent-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1 1;
}

span.coming-up-opponent-name {
  font-size: 1rem;
  border: 2px solid #707070;
  text-align: center;
  width: 100px;
  height: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
  border-radius: 10px;
}

div.jumbotron-upcoming-games-card {
  height: 140px;
  margin-top: 0.15rem;
  text-align: center;
}

div.upcoming-games-row > div {
  transform: scale(0.8);
}

div.upcoming-games-row:first-child > div:first-child {
  transform-origin: right 90%;
}

div.upcoming-games-row:first-child > div:last-child {
  transform-origin: left 90%;
}

div.upcoming-games-row:last-child > div:first-child {
  transform-origin: right 10%;
}

div.upcoming-games-row:last-child > div:last-child {
  transform-origin: left 10%;
}

video.jumbotron-video {
  object-fit: fill;
  height: 100%;
}

.player-rank-pie * {
  box-sizing: border-box;
}

.player-rank-pie {
  position: relative;
  z-index: 0;
}
.player-rank-slice {
  -webkit-clip-path: polygon(50% 0%, 50% 100%, 100% 100%, 100% 0%);
          clip-path: polygon(50% 0%, 50% 100%, 100% 100%, 100% 0%);
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.player-rank-slice-fill {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-clip-path: polygon(50% 0%, 50% 100%, 0% 100%, 0% 0%);
          clip-path: polygon(50% 0%, 50% 100%, 0% 100%, 0% 0%);
  position: absolute;
  z-index: 2;
}

.player-rank-bg-fill {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  display: none;
  z-index: 1;
  -webkit-clip-path: polygon(50% 0%, 50% 100%, 0% 100%, 0% 0%);
          clip-path: polygon(50% 0%, 50% 100%, 0% 100%, 0% 0%);
  transform: rotate(180deg);
}

.loading-spinner {
  display: inline-block;
  width: 70px;
  height: 70px;
  border: 4px solid rgba(0, 120, 138, 0.3);
  border-radius: 50%;
  border-top-color: #00788a;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

.loading-spinner-small {
  width: 40px;
  height: 40px;
  border-width: 3px;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.confirm-message {
  text-align: center;
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  color: #006d75;
  position: relative;
  transform: translateY(50%);
}

.confirm-options {
  transform: scale(0.75);
}

.confirm-overlay-top {
  flex: 1 1;
  width: 100%;
}

div.stat-wrap {
  font-size: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.stats-container {
  margin-top: 2rem;
  width: 50%;
}

div.stick-btn-wrap {
  position: relative;
  width: 415px;
  height: 110px;
}

span.stick-btn-link {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

span.stick-btn-link-disabled {
  pointer-events: none;
}

.stick-btn-img {
  position: absolute;
  bottom: -29%;
  left: 0;
}

.stick-btn-img-inverse {
  bottom: -40%;
}

div.stick-btn-small {
  transform: scale(0.8);
}

p.stick-btn-text-right {
  margin-top: 0.15rem;
}

div.amount-indicator-wrap {
  display: inline-block;
  position: relative;
}

div.amount-indicator {
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 75px;
  height: 75px;
  border: 10px solid #4b4b4b;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.amount-indicator-pointer {
  width: 15px;
  height: 15px;
  background-color: #4b4b4b;
  position: absolute;
  right: 0;
  top: 50%;
  right: 0;
}

div.amount-indicator-right div.amount-indicator-pointer {
  right: 0;
  transform: translate(5px, -50%) rotate(45deg);
}

div.amount-indicator-left div.amount-indicator-pointer {
  left: 0;
  transform: translate(-5px, -50%) rotate(45deg);
}

div.amount-indicator-bottom div.amount-indicator-pointer {
  top: 100%;
  left: 50%;
  transform: translate(-50%, -9px) rotate(45deg);
}

div.amount-indicator-top div.amount-indicator-pointer {
  top: 0%;
  left: 50%;
  transform: translate(-50%, -5px) rotate(45deg);
}

p.amount-indicator-amount {
  letter-spacing: 0.1rem;
}

p.indicator-label {
  font-size: 1.2rem;
  padding: 0.5rem 0;
}

div.budget-slider-wrap {
  position: relative;
}

div.slider-wrap {
  position: relative;
  width: calc(100% - 75px);
  margin: 3.5rem auto 0 auto;
}

div.savings-indicator-wrap {
  position: absolute;
  top: 0;
  text-align: center;
  transition: opacity 0.3s ease;
}

div.top-indicators-container {
  top: 30%;
  left: 3rem;
  display: flex;
  align-items: baseline;
  text-align: center;
  position: absolute;
}

div.spent-indicator-wrap > p,
div.spending-indicator-wrap > p {
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.spending-indicator-wrap p {
  color: #002f6c;
}

div.spent-indicator-wrap p {
  color: #dc2d2d;
  font-weight: bold;
}

div.budget-slider-stick-wrap {
  text-align: center;
  margin: 0 auto;
}

div.slider-input-wrap {
  position: relative;
  height: 75px;
  margin-left: auto;
}

div.slider-input-wrap-inner {
  width: calc(100% + 75px);
  transform: translateX(-37.5px);
  height: 100%;
  position: relative;
}

.slider-wrap input:focus {
  background: none;
  outline: none;
}

.slider-wrap input {
  width: 100%;
  -webkit-appearance: none;
  z-index: 2;
  opacity: 0;
  direction: rtl;
  height: 100%;
  background: none;
}

.slider-wrap input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: #37adbf;
  cursor: pointer;
  border: 0 !important;
  transform: translateY(calc(-50% + 4px));
}

.slider-wrap input::-moz-budget-slider-wrap-thumb {
  -webkit-appearance: none;
  width: 75px;
  height: 75px;
  margin: -3px 0 0;
  border-radius: 50%;
  background: #37adbf;
  cursor: pointer;
  border: 0 !important;
}

.slider-wrap input::-ms-thumb {
  width: 75px;
  height: 75px;
  margin: -3px 0 0;
  border-radius: 50%;
  background: #37adbf;
  cursor: pointer;
  border: 0 !important;
}

.slider-wrap input::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #b2b2b2;
  border-radius: 3px;
}

.slider-wrap input::-moz-budget-slider-wrap-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #b2b2b2;
  border-radius: 3px;
}

.slider-wrap input::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #b2b2b2;
  border-radius: 3px;
}

div.slider-scale-wrap {
  height: 40px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  transform: translateY(calc(-100% - 15px));
}

div.ticks-container {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
}

span.tick {
  position: relative;
}
span.tick:after {
  content: '';
  position: absolute;
  background-color: #000;
  width: 2px;
  height: 15px;
  top: 0;
  left: 0px;
  transform: translateX(-50%);
}

div.tick-labels-container {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
}

span.tick-label {
  display: inline-block;
  position: relative;
}

span.tick-label-inner {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-50%);
}

div.budget-equation-wrap {
  border: 10px solid #00788a;
  border-radius: 15px;
  background: #4b4b4b;
  color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 0.25rem 0;
  width: 500px;
  margin: 0 auto;
}

h4.budget-equation-title {
  text-align: center;
  font-size: 1.75rem;
  font-weight: bold;
  white-space: nowrap;
}

div.equation-wrap {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0 0.25rem 0;
}

div.equation-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.equation-inner.savings {
  margin: 0 1.5rem 0 1rem;
}

span.equation-title {
  font-size: 1.15rem;
  font-weight: bold;
}

span.equation-amount {
  font-size: 2.5rem;
  margin-top: 0.5rem;
}

div.equation-inner.savings > span.equation-amount {
  position: relative;
}

span.equation-operator {
  font-size: 3rem;
  align-self: flex-end;
}

div.player-card-wrap {
  display: inline-flex;
  flex-direction: column;
  border-radius: 15px;
}

div.player-card-wrap.player-card-clickable {
  cursor: pointer;
}

div.player-card-wrap-empty {
  width: 85px;
  height: 118px;
}

div.player-card-empty-inner {
  width: 100%;
  height: 100%;
  flex: 1 1;
  background-color: #e5e5e5;
  border-radius: 15px;
}

p.position-text {
  text-align: center;
  font-weight: bold;
}

div.player-card-header {
  width: 100%;
  position: relative;
  z-index: 0;
  background: linear-gradient(to right, #ea7200, #f3901d);
}

div.player-card-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border-radius: 5px;
  padding: 0 0.5rem;
}

div.player-card-header:before,
div.player-card-header:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 5px;
}

div.player-card-header:after {
  transform-origin: left bottom;
  transform: skewX(-12deg);
}

div.player-card-header:before {
  transform-origin: right bottom;
  transform: skewX(12deg);
}

div.player-card-wrap-large div.player-card-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

div.player-card-wrap-large div.player-card-header:before {
  border-top-left-radius: 20px;
  transform: skewX(12deg);
}

div.player-card-wrap-large div.player-card-header:after {
  border-top-right-radius: 20px;
  transform: skewX(-12deg);
}

div.player-card-body {
  text-align: center;
  flex: 1 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 0.25rem;
}

div.player-card-color-shark {
  background: linear-gradient(to bottom, #00788a, #fff);
}

div.player-card-color-reg {
  background: linear-gradient(to bottom, #e5e5e5, #fff);
}

div.player-card-img-wrap {
  display: inline-block;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
}

p.player-card-player-name {
  color: #fff;
}

div.player-card-clickable .player-card-inner {
  cursor: pointer;
}

div.player-ranks {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

p.add-player-text {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
}

div.nav-container {
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  background-color: #ffffff;
}

div.exit-stick-box,
div.home-title-box,
div.settings-link-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

div.exit-stick-box {
  justify-content: flex-start;
  position: relative;
  top: -110px;
}

div.settings-link-box {
  justify-content: flex-end;
}

.objectives-board-wrap {
  border: 10px solid #00788a;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  background: #4b4b4b;
  color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  min-width: 350px;
  transition: all 0.3s ease;
}

.objectives-board-wrap.is-urgent {
  border-color: #ffd782;
}

.objective-level-box {
  display: flex;
  justify-content: space-between;
  font-size: 1.75rem;
  font-weight: bold;
}

.ordered-list {
  list-style-type: decimal;
  transition: all 0.3s ease;
  list-style-position: inside;
}

.ordered-list > li {
  margin: 0.5rem 0;
}

.ordered-list > li.objective-complete {
  text-decoration: line-through;
  opacity: 0.8;
}

.ordered-list > li.is-urgent {
  color: #ffd782;
}

.objective-text-small {
  font-size: 1.05rem;
}

div.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.close-btn {
  position: absolute;
  top: 3.5rem;
  right: 4.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: inline-block;
}

div.market-players-board-inner {
  border: 5px solid #4b4b4b;
  border-radius: 5px;
  padding: 0 1rem 1rem 1rem;
  min-height: 206.86px;
}

h3.market-players-board-title {
  font-size: 1.5rem;
  text-align: center;
  padding: 0.5rem 0;
}

div.market-players-board-btns-container {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

div.market-players-board-tab-btn.disabled {
  background-color: #707070;
  cursor: auto;
  cursor: initial;
}

div.market-players-board-tab-btn {
  cursor: pointer;
  display: inline-flex;
  position: relative;
  font-size: 1.65rem;
  color: #fff;
  align-items: center;
  justify-content: center;
  background-color: #ea7200;
  height: 50px;
  width: 175px;
  border-radius: 5px;
  z-index: 1;
}

div.market-players-board-tab-btn-inner {
  height: 22px;
  display: flex;
  align-items: center;
  color: #fff;
}

div.market-players-board-tab-btn:before,
div.market-players-board-tab-btn:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  bottom: 0;
  border-radius: 5px;
}

div.market-players-board-tab-btn:after {
  transform-origin: left top;
  transform: skewX(-10deg);
}

div.market-players-board-tab-btn:before {
  transform-origin: right top;
  transform: skewX(10deg);
}

div.market-players-board-inner {
  flex: 1 1;
}

div.market-players-board-players-wrap {
  display: flex;
  align-items: center;
}

div.sharkie-wrap {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

img.bubble-img,
img.sharkie-img {
  display: inline-block;
  width: 100%;
}

div.bubble-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.bubble-img {
  width: 110%;
  position: absolute;
  top: 0;
}

div.bubble-wrap:not(.bubble-inverse) img {
  left: 0;
}

div.bubble-inverse img {
  right: 0;
}

p.slide-msg {
  position: relative;
  padding: 1rem 1.5rem;
  text-align: center;
}

div.bubble-inverse img,
div.sharkie-inverse img {
  transform: scaleX(-1);
}

div.sharkie-img-wrap {
  width: 85%;
  position: absolute;
  top: 0;
  left: 0;
}

div.sharkie-present-wrap > div.sharkie-img-wrap,
div.sharkie-presentInverse-wrap > div.sharkie-img-wrap {
  width: 110%;
}

div.tutorial-container,
div.tutorial-container-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.tutorial-container {
  background-color: rgba(255, 255, 255, 0.75);
  transition: background-color 0.2s ease;
}

div.tutorial-container.transparent {
  background-color: rgba(255, 255, 255, 0);
}

.tutorial-container-inner {
  flex: 1 1;
}

div.slide-wrap {
  position: relative;
  height: 307px;
  width: 40%;
  z-index: 2000;
}

div.slide-wrap-small {
  height: 228px;
  width: 30%;
}

div.btns-wrap {
  position: absolute;
  left: -100px;
  top: 0;
  right: -100px;
  display: flex;
  justify-content: space-between;
}

img.action-btn {
  width: 85px;
  height: 85px;
}

button.slide-btn {
  display: inline-flex;
  flex-direction: column;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  background: none;
}

div.home-page-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.home-cards-row {
  justify-content: center;
  display: flex;
  width: 95%;
  margin: 0 auto;
  position: relative;
}

.objectives-board-container {
  position: relative;
  flex: 1 1;
}

div.money-left-card-box {
  padding: 0 2rem;
}

div.level-stick-card {
  margin: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 5px solid #4b4b4b;
  border-radius: 10px;
  position: relative;
  width: 210px;
  height: 285px;
}

.sharkie-btn-container {
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 35%);
}

.stick-btn-text {
  transition: all 0.3s ease;
}

div.hockey-sticks-container {
  position: relative;
  margin-top: 2.75rem;
}

div.hockey-sticks-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.hockey-sticks-row-2 {
  margin-top: 2.5rem;
  z-index: 1;
}

div.stick-btn-container:first-child {
  text-align: right;
  right: 30px;
  position: relative;
}

div.stick-btn-container:last-child {
  left: 30px;
  position: relative;
}

div.team-page-board-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

div.team-page-board-header > h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

div.team-page-board-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 2rem;
}

div.team-page-board-inner {
  display: flex;
  height: calc(100% - 80px);
}

div.team-page-board-left {
  padding: 0 1rem;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

div.team-page-board-right {
  flex: 1 1;
  padding-right: 1rem;
}

div.team-players-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  position: relative;
  flex: 1 1;
  padding-top: 0.25rem;
}

div.team-players-row-2 {
  flex: 1.5 1;
  align-items: flex-start;
  padding-top: 1.25rem;
}

div.team-players-card {
  height: 335px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

div.team-players-card {
  color: #006b77;
  border: 5px solid #4b4b4b;
  border-radius: 5px;
  background: white;
}

div.scout-page-board-inner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
}

div.scout-page-board-inner > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.5rem;
}

div.scout-page-board-header {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.scout-page-board-header-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
}

p.scout-page-helper-text {
  font-size: 1.1rem;
  margin: 0.25rem auto;
  text-align: center;
  width: 35%;
}

p.money-level-text {
  font-size: 0.8rem;
  text-align: center;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

div.scout-board {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 5px solid #4b4b4b;
  border-radius: 10px;
  background: #fff;
  width: 406px;
  height: 487px;
  position: relative;
}

div.scout-board-disabled {
  pointer-events: none;
}

div.scout-page-board-left > p {
  left: 0;
  text-align: center;
  margin-top: -15px;
  font-size: 0.8rem;
}

div.scout-page-board-right {
  min-width: 407.97px;
  min-height: 512.93px;
}

div.available-player-row,
div.offered-player-row-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.available-player-row {
  justify-content: space-around;
}

div.player-card-drop {
  min-width: calc(90px + 1rem);
  min-height: calc(123px + 1rem);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.player-card-drop-small {
  min-width: calc(72px + 0.5rem);
  min-height: calc(95.76px + 0.5rem);
}

div.offered-player-row {
  width: 392px;
  height: 144px;
  border: 5px solid #4b4b4b;
  border-radius: 10px;
  background: #fff;
}

div.offered-player-row-wrap {
  position: relative;
}

span.money-level-short {
  font-size: 1.1rem;
  position: absolute;
  top: calc(0.5rem + 17px);
  left: 0.5rem;
  font-weight: bold;
}

div.offered-player-row-inner {
  height: 100%;
}

div.offered-player-row-inner.level-3 {
  width: 88%;
  margin: 0 auto;
}

div.offered-player-row div.player-card-drop {
  padding: 0.5rem 0.5rem;
}

div.offered-player-row div.player-card-drop-small {
  padding: 0.5rem 0.25rem;
}

.check-btn-small {
  margin-left: 0.5rem;
}

div.empty-player-slot {
  width: 90px;
  height: 106.4px;
  border-radius: 15px;
  border: 3px solid #707070;
  background-color: #e5e5e5;
  animation: fadeIn 0.2s forwards ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

div.budget-page-board-inner {
  flex-direction: column;
  padding: 1rem;
  position: relative;
}

p.helper-text {
  width: 40%;
  margin: 1rem auto;
  text-align: center;
}

div.budget-slider-container {
  margin-top: -6.5rem;
}

div.rollover-budget-wrap {
  position: absolute;
  left: 1rem;
  top: 1rem;
  text-align: center;
  width: 150px;
}

div.rollover-budget-wrap > p {
  font-size: 1rem;
  margin-top: 0.5rem;
}

span.rollover-budget-indicator-wrap {
  display: inline-block;
  cursor: pointer;
}

div.rollover-budget-overlay-header-wrap > p {
  text-align: center;
  width: 500px;
  margin: 0 auto;
}

div.rollover-budget-slider-wrap {
  margin-top: -1rem;
  margin-bottom: 3rem;
}

div.season-page-board-top {
  flex: 1 1;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

div.season-page-board-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  padding: 0 1rem 0.5rem 1rem;
}

div.game-btn-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

div.game-btn-container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.game-btn-label-wrap {
  height: 37.5px;
  margin-bottom: 0.25rem;
}

div.game-btn-label-wrap > span {
  font-size: 1rem;
  width: 265px;
  text-align: center;
  display: inline-block;
}

div.game-count-wrap {
  font-size: 1.1rem;
  margin-top: -0.2rem;
}

div.jumbotron-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

div.student-team-rank-container,
div.opposing-team-rank-container {
  position: relative;
}

div.student-team-rank-container {
  left: -55px;
}

div.opposing-team-rank-container {
  right: -53px;
}

div.standings-board-container > h6 {
  font-weight: 700;
  font-size: 1.5rem;
}

div.standings-board-wrap-inner {
  width: 320px;
  height: 150px;
  border-radius: 10px;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border: 5px solid #707070;
  transform-origin: bottom right;
}

div.standings-board-wrap-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.standings-board-wrap-inner {
  padding: 0;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

div.standings-board-row {
  display: flex;
  align-items: center;
  color: #121210;
  font-size: 1.1rem;
  width: 100%;
  flex: 1 1;
  padding-left: 0.5rem;
}

div.standings-board-top-row {
  font-size: 0.8rem;
  border-bottom: 1px solid #121210;
  max-height: 20px;
  justify-content: center;
}

div.standings-board-row > span:first-child {
  flex: 1 1;
}

div.standings-board-row > span:last-child {
  min-width: 75px;
  max-width: 75px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #121210;
  align-self: stretch;
}

div.cheermeter-container {
  padding-left: 1.5rem;
}

svg.game-button-warming-up {
  animation: spin 5s cubic-bezier(0.75, 1.65, 0.7, -0.65);
  -webkit-animation: spin 5s cubic-bezier(0.75, 1.65, 0.7, -0.65);
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

svg.game-button-game-on g.active-puck-border {
  animation: fillShift 2.5s ease-in-out infinite;
  -webkit-animation: fillShift 2.5s ease-in-out infinite;
}

@keyframes fillShift {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

div.teacher-dashboard-header {
  min-height: 125px;
  max-height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

div.teacher-dashboard-header > h1 {
  font-size: 1.75rem;
  color: #002f6c;
  font-weight: bold;
}

div.teacher-dashboard-body {
  padding: 3rem;
  background-color: rgba(238, 238, 238, 0.5);
  flex: 1 1;
  min-height: calc(100% - 125px);
  overflow-y: auto;
}

div.class-stats-wrap {
  padding: 1rem;
  margin: 1rem 0;
}

div.class-stats-wrap > h4 {
  margin-bottom: 1rem;
}

div.class-stat {
  display: flex;
  margin: 0.5rem 0;
}

div.class-stat > p {
  color: #002f6c;
  font-size: 1.1rem;
}

p.class-stat-value {
  margin-left: 1rem;
  font-weight: bold;
}

div.class-stats-wrap,
div.student-table {
  background-color: #fff;
  border-radius: 3px;
}

div.student-table-row {
  display: flex;
  padding: 1rem;
}

div.student-table-row:not(:first-child):not(:last-child) {
  border-bottom: 1px solid #00788a;
}

div.student-table-header-row {
  border-bottom: 2px solid #00788a;
}

div.student-table-row > div {
  font-size: 1rem;
  flex: 1 1;
  align-items: center;
  display: flex;
  padding: 0 0.25rem;
}

div.student-table-header-row > div {
  font-size: 0.9rem;
  font-weight: 700;
  color: #00788a;
}

div.teacher-dashboard-btns-wrap > button:first-child {
  margin-right: 0.5rem;
}

span.action-btn {
  cursor: pointer;
}

span.action-btn:first-child {
  margin-right: 0.5rem;
}

div.teacher-dashboard-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 1024px;
  margin: 0 auto;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.modal-bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

div.teacher-dashboard-modal-inner {
  background-color: #fff;
  border-radius: 3px;
  width: 85%;
  margin: 0 auto;
  padding: 3rem;
  position: relative;
  z-index: 2;
  max-height: 85vh;
  overflow: auto;
}

.close-btn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: inline-block;
}

div.teacher-dashboard-modal-inner > div.modal-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

div.modal-template label {
  margin-top: 1rem;
  display: block;
}

div.modal-template input:not([type='file']) {
  border: 1px solid #707070;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  padding: 0px 10px;
}

div.modal-template input[type='file'] {
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 1rem;
  margin: 1rem 0;
}

div.modal-template button {
  margin-top: 1rem;
}

div.confirm-text {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

div.empty-table {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.snackbar-wrap {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  background-color: #f1f1f1;
  border-radius: 3px;
  width: 400px;
  max-width: 90%;
  padding: 1rem;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.snackbar-wrap > span {
  display: inline-block;
  margin-left: 0.5rem;
}

div.table-wrap {
  background-color: #fff;
  border-radius: 3px;
}

div.table-header-row,
div.table-row-wrap:not(:last-child) {
  border-bottom: 1px solid #00788a;
}

div.table-row {
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
}

div.table-header-row {
  padding: 0.75rem 1rem;
  cursor: auto;
  cursor: initial;
  color: #00788a;
  border-bottom: 2.5px solid #00788a;
}

div.table-header-row > div {
  font-size: 0.9rem;
  font-weight: bold;
}

div.table-row > div {
  flex: 1 1;
  text-align: left;
}

span.table-arrow-wrap {
  min-width: 3rem;
  max-width: 3rem;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

span.table-arrow {
  display: inline-block;
  transition: transform 0.3s ease;
}

div.table-pagination-wrap {
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

div.table-expandable-content {
  transition: all 0.3s ease;
  overflow: hidden;
}

input.current-page-input:focus {
  border-color: #f3901d;
}

div.current-page-wrap > span {
  display: inline-block;
  cursor: pointer;
}

div.current-page-wrap > span:first-child {
  margin-right: 0.75rem;
}

div.current-page-wrap > span:last-child {
  margin-left: 0.75rem;
}

input.current-page-input {
  background: none;
  margin: 0 0.5rem;
  outline: none;
  border: 1px solid #00788a;
  border-radius: 3px;
  width: 50px;
  text-align: center;
  padding: 0.25rem 0;
}

div.empty-table-wrap {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.admin-page-header {
  min-height: 125px;
  max-height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

div.admin-page-header > a {
  font-size: 1.75rem;
  color: #002f6c;
  font-weight: bold;
}

div.admin-page-body-container {
  padding: 3rem;
  background-color: rgba(238, 238, 238, 0.5);
  flex: 1 1;
  min-height: calc(100% - 125px);
  overflow-y: auto;
}

div.admin-total-wrap {
  font-size: 2rem;
  background-color: #fff;
  color: #002f6c;
  border-radius: 3px;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.admin-total-wrap {
  margin-bottom: 2rem;
}

div.admin-total-left {
  display: flex;
  align-items: center;
}

div.admin-total-left > div.loading-spinner,
div.admin-total-left > span {
  margin-right: 0.65rem;
}

div.admin-total-left > span {
  font-size: 2.5rem;
  color: #00788a;
}

div.admin-loading-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.teacher-browser-header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.teacher-browser-header-wrap > h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #002f6c;
}

div.admin-teacher-detail-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1rem;
}

div.get-time-spent-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

div.get-time-spent-wrap > span {
  color: #002f6c;
}

div.admin-teacher-details-inner {
  display: flex;
  padding: 1rem 3rem;
}

div.admin-teacher-details-left,
div.admin-teacher-details-right {
  flex: 1 1;
}

div.admin-teacher-details-right {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

div.admin-teacher-detail-action {
  position: absolute;
  bottom: 32px;
  right: 32px;
  display: flex;
  flex-direction: column;
}
div.admin-teacher-detail-action-item {
  margin: 12px;
}

span.admin-teacher-detail-label {
  display: inline-block;
  flex: 1 1;
  font-weight: bold;
  color: #00788a;
}

span.admin-teacher-detail {
  display: inline-block;
  flex: 1 1;
}

div.teacher-browser-search-wrap > p {
  margin-bottom: 1rem;
  font-size: 1rem;
}

div.teacher-search-input-wrap {
  display: flex;
  margin-bottom: 1.5rem;
}

div.teacher-search-input-wrap > span {
  flex: 0.2 1;
}

div.teacher-search-input-wrap > input {
  display: block;
  flex: 1 1;
  background: none;
  border: none;
  border-bottom: 1px solid #00788a;
  transition: all 0.2s ease;
  padding: 0 0.25rem;
}

div.teacher-search-input-wrap > input:focus {
  border-color: #f3901d;
}

div.time-spent-container {
  color: #002f6c;
  padding: 1rem;
}

div.time-spent-wrap {
  margin-top: 2rem;
}

div.time-spent-wrap > div {
  font-size: 1.5rem;
  color: #00788a;
  margin: 0.5rem 0;
}

div.teacher-browser-modal-inner {
  background-color: #fff;
  border-radius: 3px;
  width: 85%;
  margin: 0 auto;
  padding: 3rem;
  position: relative;
  z-index: 2;
  max-height: 85vh;
  overflow: auto;
}

div.teacher-browser-modal-inner > div.modal-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

div.teacher-browser-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 1024px;
  margin: 0 auto;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.modal-bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.close-btn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: inline-block;
}
div.teacher-registration-page-container {
  overflow-y: auto;
}

div.teacher-registration-page-header {
  min-height: 125px;
  max-height: 125px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
  position: relative;
}

div.teacher-registration-page-body {
  overflow: auto;
  padding: 2rem 0;
}

span.back-btn-wrap {
  display: inline-block;
  position: relative;
  z-index: 2;
}

div.teacher-registration-page-header > h2 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  z-index: 1;
}

div.teacher-registration-page-form-wrap {
  background-color: #e5e5e5;
  width: 85%;
  margin: 0 auto;
  border-radius: 3px;
  padding: 2rem 7rem;
}

div.teacher-registration-page-form-wrap > h4 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 3rem;
}

div.teacher-registration-page-form-wrap label {
  font-size: 1.3rem;
  margin-bottom: 0.25rem;
  text-align: center;
}

div.teacher-registration-page-form-wrap input {
  margin-bottom: 2rem;
  padding: 0.5rem;
  border-radius: 3px;
  border: 1.5px solid #000;
  transition: all 0.3s ease;
  width: 100%;
}

div.teacher-registration-page-form-wrap input:focus {
  border-color: #00788a;
}

div.teacher-registration-page-form-wrap select {
  width: 100%;
  padding: 0 0.5rem;
  border-radius: 3px;
  border: 1.5px solid #000;
  margin-bottom: 2rem;
}

div.teacher-registration-page-form-wrap select:focus {
  border-color: #00788a;
}

div.teacher-registration-page-form-wrap select:focus {
  border-color: #00788a;
}

div.teacher-registration-page-form-wrap p.error {
  color: #dc2d2d;
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

div.form-columns-wrap {
  display: flex;
}

div.form-columns-wrap input.col-1-input {
  margin-right: 0.5rem;
}

div.form-columns-wrap input.col-2-input {
  margin-left: 0.5rem;
}

div.form-columns-wrap > div {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
}

div.form-submit-wrap.btn-accent {
  padding: 0;
  text-align: center;
}

span.reg-form-spinner-wrap {
  display: inline-block;
  padding: 0.15rem;
}

input[type='submit'] {
  padding: 0.75rem !important;
  border: none !important;
  box-shadow: none;
  margin-bottom: 0 !important;
  font-weight: bold;
}

div.fixed-overlay-wrap {
  position: fixed;
  margin: 0 auto;
  width: 1024px;
  height: 768px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.teacher-registration-page-container::-webkit-scrollbar-track,
.teacher-registration-page-body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.teacher-registration-page-container::-webkit-scrollbar,
.teacher-registration-page-body::-webkit-scrollbar
{
	width: 15px;
	background-color: #F5F5F5;
}

.teacher-registration-page-container::-webkit-scrollbar-thumb,
.teacher-registration-page-body::-webkit-scrollbar-thumb
{
  border-radius: 15px;
	background-color: #F90;	
	background-image: -webkit-linear-gradient(45deg,
	                                          rgba(255, 255, 255, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, .2) 50%,
											  rgba(255, 255, 255, .2) 75%,
											  transparent 75%,
											  transparent)
}
.forgot-password-page-container {
  text-align: center;
  position: relative;
  z-index: 100;
  height: 100%;
}

.forgot-password-form-wrap {
  background-color: #e5e5e5;
  width: 50%;
  position: relative;
  margin: 1rem auto;
  border-radius: 10px;
  padding: 2rem;
}
.forgot-password-form-wrap > p.form-title {
  padding-top: 1rem;
}

.forgot-password-form-inner {
  padding: 2rem;
}

.form-field {
  margin-bottom: 1rem;
}

.form-label {
  color: #070707;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.form-ip {
  border: 1px solid #707070;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  padding: 0px 10px;
}

.error-text {
  font-size: 1rem;
  color: red !important;
  width: 100%;
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
}

div.try-again-wrap > span,
.back-to-dashboard {
  cursor: pointer;
  color: #00788a;
}

div.try-again-wrap {
  margin-top: 1rem;
  font-size: 1rem;
}

