div.season-page-board-top {
  flex: 1;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

div.season-page-board-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  padding: 0 1rem 0.5rem 1rem;
}

div.game-btn-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

div.game-btn-container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.game-btn-label-wrap {
  height: 37.5px;
  margin-bottom: 0.25rem;
}

div.game-btn-label-wrap > span {
  font-size: 1rem;
  width: 265px;
  text-align: center;
  display: inline-block;
}

div.game-count-wrap {
  font-size: 1.1rem;
  margin-top: -0.2rem;
}

div.jumbotron-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

div.student-team-rank-container,
div.opposing-team-rank-container {
  position: relative;
}

div.student-team-rank-container {
  left: -55px;
}

div.opposing-team-rank-container {
  right: -53px;
}

div.standings-board-container > h6 {
  font-weight: 700;
  font-size: 1.5rem;
}

div.standings-board-wrap-inner {
  width: 320px;
  height: 150px;
  border-radius: 10px;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border: 5px solid #707070;
  transform-origin: bottom right;
}

div.standings-board-wrap-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.standings-board-wrap-inner {
  padding: 0;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

div.standings-board-row {
  display: flex;
  align-items: center;
  color: #121210;
  font-size: 1.1rem;
  width: 100%;
  flex: 1;
  padding-left: 0.5rem;
}

div.standings-board-top-row {
  font-size: 0.8rem;
  border-bottom: 1px solid #121210;
  max-height: 20px;
  justify-content: center;
}

div.standings-board-row > span:first-child {
  flex: 1;
}

div.standings-board-row > span:last-child {
  min-width: 75px;
  max-width: 75px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #121210;
  align-self: stretch;
}

div.cheermeter-container {
  padding-left: 1.5rem;
}

svg.game-button-warming-up {
  animation: spin 5s cubic-bezier(0.75, 1.65, 0.7, -0.65);
  -webkit-animation: spin 5s cubic-bezier(0.75, 1.65, 0.7, -0.65);
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

svg.game-button-game-on g.active-puck-border {
  animation: fillShift 2.5s ease-in-out infinite;
  -webkit-animation: fillShift 2.5s ease-in-out infinite;
}

@keyframes fillShift {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
