div.amount-indicator-wrap {
  display: inline-block;
  position: relative;
}

div.amount-indicator {
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 75px;
  height: 75px;
  border: 10px solid #4b4b4b;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.amount-indicator-pointer {
  width: 15px;
  height: 15px;
  background-color: #4b4b4b;
  position: absolute;
  right: 0;
  top: 50%;
  right: 0;
}

div.amount-indicator-right div.amount-indicator-pointer {
  right: 0;
  transform: translate(5px, -50%) rotate(45deg);
}

div.amount-indicator-left div.amount-indicator-pointer {
  left: 0;
  transform: translate(-5px, -50%) rotate(45deg);
}

div.amount-indicator-bottom div.amount-indicator-pointer {
  top: 100%;
  left: 50%;
  transform: translate(-50%, -9px) rotate(45deg);
}

div.amount-indicator-top div.amount-indicator-pointer {
  top: 0%;
  left: 50%;
  transform: translate(-50%, -5px) rotate(45deg);
}

p.amount-indicator-amount {
  letter-spacing: 0.1rem;
}

p.indicator-label {
  font-size: 1.2rem;
  padding: 0.5rem 0;
}
