div.home-page-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.home-cards-row {
  justify-content: center;
  display: flex;
  width: 95%;
  margin: 0 auto;
  position: relative;
}

.objectives-board-container {
  position: relative;
  flex: 1;
}

div.money-left-card-box {
  padding: 0 2rem;
}

div.level-stick-card {
  margin: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 5px solid #4b4b4b;
  border-radius: 10px;
  position: relative;
  width: 210px;
  height: 285px;
}

.sharkie-btn-container {
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 35%);
}

.stick-btn-text {
  transition: all 0.3s ease;
}

div.hockey-sticks-container {
  position: relative;
  margin-top: 2.75rem;
}

div.hockey-sticks-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.hockey-sticks-row-2 {
  margin-top: 2.5rem;
  z-index: 1;
}

div.stick-btn-container:first-child {
  text-align: right;
  right: 30px;
  position: relative;
}

div.stick-btn-container:last-child {
  left: 30px;
  position: relative;
}
