div.player-card-wrap {
  display: inline-flex;
  flex-direction: column;
  border-radius: 15px;
}

div.player-card-wrap.player-card-clickable {
  cursor: pointer;
}

div.player-card-wrap-empty {
  width: 85px;
  height: 118px;
}

div.player-card-empty-inner {
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: #e5e5e5;
  border-radius: 15px;
}

p.position-text {
  text-align: center;
  font-weight: bold;
}

div.player-card-header {
  width: 100%;
  position: relative;
  z-index: 0;
  background: linear-gradient(to right, #ea7200, #f3901d);
}

div.player-card-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border-radius: 5px;
  padding: 0 0.5rem;
}

div.player-card-header:before,
div.player-card-header:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 5px;
}

div.player-card-header:after {
  transform-origin: left bottom;
  transform: skewX(-12deg);
}

div.player-card-header:before {
  transform-origin: right bottom;
  transform: skewX(12deg);
}

div.player-card-wrap-large div.player-card-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

div.player-card-wrap-large div.player-card-header:before {
  border-top-left-radius: 20px;
  transform: skewX(12deg);
}

div.player-card-wrap-large div.player-card-header:after {
  border-top-right-radius: 20px;
  transform: skewX(-12deg);
}

div.player-card-body {
  text-align: center;
  flex: 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 0.25rem;
}

div.player-card-color-shark {
  background: linear-gradient(to bottom, #00788a, #fff);
}

div.player-card-color-reg {
  background: linear-gradient(to bottom, #e5e5e5, #fff);
}

div.player-card-img-wrap {
  display: inline-block;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
}

p.player-card-player-name {
  color: #fff;
}

div.player-card-clickable .player-card-inner {
  cursor: pointer;
}

div.player-ranks {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

p.add-player-text {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
}
